/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CartProductStatusInformation {
    &-InfoWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        svg {
            @include mobile {
                width: 68px;
                height: 20px;
            }
        }
    }

    &-StatusInformation {
        background-color: $orange-color-1;
        display: flex;
        font-size: 14px;
        line-height: 20px;
        padding: 14px 20px 14px 22px;
        border: 1px solid $yellow-status-color;
        margin-bottom: 6px;
        font-weight: 400;
        border-radius: 2px;
        width: fit-content;

        @include desktop {
            align-items: center;
        }

        &:last-child {
            margin-bottom: 18px;
        }

        &_isCouponCode {
            border-color: $green-color-3;
            background-color: $green-color-4;
        }

        &_isNotEnoughProduct, &_isDifferentDeliveryMethods,
        &_isVirtualAndOnlyBookstoreProduct, &_isConflictInStoresAndProducts, &_isZeroPricePresaleProduct {
            border-color: $red-color-3;
            background-color: $red-color-4;

            svg {
                path {
                    stroke: $extra-color-10;
                }
            }
        }

        &_isNotEnoughProduct {
            b {
                margin-right: 4px;
            }
        }

        div {
            margin-left: 12px;

            b {
                margin-left: 4px;
            }
        }
    }
}
