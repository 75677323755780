/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

[dir="ltr"] .ProductCard {
    @include mobile {
        margin-bottom: 20px;
    }

    @include desktop {
        min-height: 407px;

        &_layout_list {
            min-height: auto;
        }

        &:last-child {
            margin-bottom: 5px;
        }
    }

    &-Omnibus {
        font-weight: 400;
        line-height: 18px;
        font-size: 12px;
        color: $font-color-1;
        text-align: center;
    }

    &-NotifyButton {
        &:focus {
            color: #fff;
        }
    }

    .ProductPrice {
        min-height: fit-content;
        margin: 0 auto;

        @include mobile {
            margin: 4px auto;

            &-Price {
                align-items: flex-start;
            }
        }

        &-HighPrice {
            color: $font-color-2;
            font-size: 14px;
            line-height: 18px;
            opacity: 1;
            order: -1;
            text-decoration-line: line-through;
            margin: 0;
            font-weight: 400;

            @include mobile {
                font-size: 16px;
                line-height: 16px;
                color: $font-color-2;
            }
        }

        &-PriceBadge {
            line-height: 20px;
        }

        &-PriceValue {
            color: $font-color-1;
            font-size: 18px;
            line-height: 24px;
            margin-top: 0;

            @include mobile {
                font-weight: 700;
                line-height: 20px;
                color: $font-color-1;
            }
        }

        &_hasDiscount {

            .ProductPrice {
                &-PriceValue {
                    color: $primary-color-1;
                }
            }
        }
    }

    &-Content {
        padding-top: 0;
        padding-bottom: 6px;

        @include desktop {
            padding-left: 10px;
            padding-right: 10px;
        }

        .ProductCard-Omnibus {
            @include mobile {
                display: none;
            }
        }

        .TextPlaceholder {
            overflow-x: hidden;
        }
    }

    &-Figure {
        @include desktop {
            border-radius: 8px 8px 0 0;
            padding-top: 10px;
            overflow: hidden;
        }
    }

    &-FigureReview {
        @include desktop {
            border-radius: 8px 8px 0 0;
            padding: 0;
        }
    }

    &-LinkInnerWrapper {
        width: 100%;
        height: 100%;

        border-radius: 8px;

        &:hover {
            @include desktop {
                box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.15);
                position: absolute;
                height: fit-content;
            }
        }

        &_loaded:hover {
            .ProductCard {
                &-Name {
                    display: block;
                    height: auto;
                }


                &-Content .ProductCard-Omnibus {
                    @include desktop {
                        display: none;
                    }
                }

                &-VisibleOnHover {
                    @include desktop {
                        border-radius: 0 0 8px 8px;
                        box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.15);
                    }
                }
            }
        }
    }

    &-Name {
        display: -webkit-box;
        font-size: 14px;
        height: 42px;
        min-height: 42px;
        overflow: hidden;
        text-align: center;
        white-space: pre-wrap;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 400;
        line-height: 20px;
        color: $font-color-1;

        @include mobile {
            font-size: 16px;
            height: 30px !important;
            align-content: center;
        }

    }

    &-Picture {
        padding-bottom: 100%;
    }

    &-Price {
        align-items: flex-end;
        display: flex;
        flex-direction: row-reverse;
        gap: 6px;
        justify-content: center;
    }

    &-Stock {
        align-items: center;
        border-radius: 24px;
        display: flex;
        font-size: 12px;
        min-height: 24px;
        padding: 0 0 0 24px;
        color: $font-color-2;
        text-align: center;
        margin: 4px auto;
        text-transform: lowercase;

        @include mobile {
            font-size: 14px;
            margin-bottom: 5px;
            padding: 0 0 0 16px;
            min-height: 40px;
        }

        &::before {
            background: $red-color-2;
            border-radius: 8px;
            content: '';
            height: 8px;
            left: 8px;
            position: absolute;
            width: 8px;

            @include mobile {
                left: 0;
            }
        }

        &_status {
            &::before {
                background: $green-color-2;
            }

            &_announcements,
            &_presale,
            &_to_order {
                &::before {
                    background: $orange-color-2;
                }
            }
        }

        &_onlyBookstore {
            &::before {
                background: $orange-color-2;

                @include mobile {
                    margin-left: 25px;
                }

                @media (max-width: 350px) {
                    margin-left: 12px;
                }
            }
        }
    }

    &-VisibleOnHover {
        @include mobile {
            position: static;
        }

        .ProductCard-Omnibus {
            margin-top: 10px;
        }

        .Button {
            font-size: 14px;
            height: 36px;

            @include mobile {
                font-size: 16px;

                &_isHollow:focus {
                    color: $primary-color-1;
                }
            }

            @media (max-width: 355px) {
                font-size: 14px;
            }

            @media (max-width: 330px) {
                font-size: 12px;
            }

            &.AddToCart {
                background-color: $primary-color-1;
            }
        }

        @include mobile {
            align-items: center;
            display: flex;
            justify-content: center;
            opacity: 1;
            width: inherit;

            .Button {
                height: 36px;
                line-height: 18px;
                min-width: unset;
                padding: 9px 20px;
                background-color: initial;
            }
        }

        .ProductActions {
            &-AttributesWrapper {
                display: none;
            }
        }
    }

    &-AddToCartWrapper,
    .AskAboutProduct-Wrapper {
        width: 100%;
        margin: 0 auto;
    }

    &-Footer {
        padding: 0;

        @include mobile {
            width: 100%;
        }

        >* {
            margin-right: 0;
        }

        button {
            @include mobile {
                background-color: $white;
            }
        }

        .Button {
            min-height: 36px;
            height: 36px;
            padding-left: 5px;
            padding-right: 5px;
            width: 100%;
        }
    }

    &-AnnouncementInfo {
        color: $yellow-status-color;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 -10px;

        @include mobile {
            font-size: 16px;
            min-height: 28px;
        }

        svg {
            margin-right: 6px;

            path {
                stroke: $yellow-status-color;
            }
        }
    }

    &_layout {
        &_list {
            width: 100%;
            border-top: 1px solid #D3D8E6;
            margin: 0;
            padding: 40px 20px 40px 10px;

            .ProductCard-StatusDot {
                margin-right: 6px;
            }

            .ProductCard-Figure {
                padding-top: 0;
            }

            .ProductCard-Content_layout_list {
                padding: 0;
                max-width: 100%;
                width: 100%;
            }

            .ProductCard-Link {
                width: 100%;

                .Image {
                    width: 159px;
                }
            }

            .Button {
                height: 42px;
                min-height: 42px;
            }

            .ProductCard-MainInfo,
            .ProductCard-MiddleContent {
                display: flex;
                justify-content: space-between;
            }

            .ProductCard-PriceWrapper {
                margin-bottom: 0;
                text-align: right;
            }

            .ProductCard-Name {
                -webkit-line-clamp: 1;
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                width: 100%;
                max-width: 579px;
                height: 26px;
                min-height: 26px;
                margin-bottom: 2px;
                text-align: left;

            }

            .ProductCard-ActionWrapper {
                align-items: flex-end;
                flex-wrap: wrap;
                justify-content: flex-end;

                .AskAboutProduct-Wrapper .Button,
                .Button {
                    margin-bottom: 0;
                    margin-right: 0;
                }

                .AskAboutProduct-Wrapper .Button {
                    width: 100%;
                    min-width: 219px;
                }

                .ProductCard-ProductActions {
                    width: 100%;
                    justify-content: flex-end;
                    margin-bottom: 5px;
                }

                .ProductCard-AddToCart {
                    margin-right: 0;
                    height: 42px;
                    min-height: 42px;
                }
            }

            .ProductCard-Price,
            .ProductReviewRating {
                justify-content: end;
            }

            .ProductCard-Price {
                margin-top: 14px;
            }

            .ProductCard-AddToCart {
                @include desktop {
                    min-width: 219px;
                }
            }

            .ProductCard-Reviews {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .StarIcon {
                    width: 20px;
                    height: 20px;
                }

                .ProductReviewRating-Counter {
                    color: #626262;
                    margin-left: 8px;
                }
            }

            .ProductCard-ReviewText {
                margin-left: 10px;
                color: $extra-color-1;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                cursor: pointer;

                @include mobile {
                    font-size: 16px
                }

                &:hover {
                    color: $primary-color-1;
                }

            }

            .ProductCard-AnnouncementInfo {
                margin-top: 38px;
                justify-content: end;
            }

            .ProductPrice-PriceValue,
            .ProductPrice_hasDiscount .ProductPrice-PriceValue {
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;

                +.ProductPrice-HighPrice {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                }
            }

            .ProductCard-Status {
                margin-top: 19px;
                margin-bottom: -22px;
                text-align: left;
            }

            .ProductPrice-PriceCustomDiscount.ProductPrice-Price>.ProductPrice-PriceValue {
                font-size: 16px;
            }

            .ProductCard-Omnibus {
                width: 100%;
                display: inline-block;
                text-align: right;
            }
        }
    }

    &-AttributeInfo {
        margin-top: 14px;

        p {
            &:last-child {
                margin-bottom: 0;
            }

            &,
            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $neutral-color-3;
            }

            margin-bottom: 4px;

            span {
                font-weight: 600;
            }
        }
    }

    &-Author {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $neutral-color-3;
        margin-bottom: 0;
    }

    .ProductActions-Omnibus {
        font-size: 10px !important;
        font-weight: 400;
        line-height: 18px;
        color: $font-color-1;
        text-align: center;
    }

    .RenderWhenVisible {
        width: 100%;
    }
}
