/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.AskAboutProduct {
    &-Wrapper {
        @include desktop {
            margin: 12px 0;
        }

        .Button {
            &_isHollow {
                width: 325px;
            }

            &_textBtn {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: $extra-color-1;
                text-transform: uppercase;
                border: none;
                height: auto;
                width: auto;
                min-height: auto;
                padding: 0;

                &:hover {
                    color: $primary-color-1;
                    background-color: transparent;
                }

                @include mobile {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }

        @include mobile {
            text-align: center;
        }
    }
}
