/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductPage {
    @include desktop {
        &-ExpandableContentContent {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 0;
        }

        &-ExpandableContentHeading {
            display: none;
        }

        &-Wrapper {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include mobile {
        padding-bottom: 0;

        &-ExpandableContentContent {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .ProductLinks {
        margin-bottom: 90px;

        &-Title {
            font-weight: 600;
            margin-bottom: 20px;
            padding: 0;
        }

        @include mobile {
            &-List {
                grid-template-columns: repeat(2, 1fr);
            }

            &-Title {
                font-size: 24px;
                margin-bottom: 10px;
                padding: 0;
            }
        }
    }

    &-PromotionBlock {
        @include desktop {
            margin: 0 auto;
            max-width: var(--content-wrapper-width);
            padding-top: 48px;
        }

        @include mobile {
            .HomepagePromoBlocks {
                padding: 0;
            }
        }
    }

    &-Placeholder {
        width: 100%;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ProductReviews {
        &-PopupPlaceholder {
            width: 100%;

            @include desktop {
                width: 748px;
                height: 462px;
            }
        }
    }
}