/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductTabs {
    border-bottom: none;
    margin-bottom: 15px;
    position: relative;

    &::before {
        background: $extra-color-6;
        border-radius: 66px;
        bottom: -9px;
        box-shadow: inset 1px 1px 4px rgba(0, 30, 74, 0.15);
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        right: 0;
    }

    &-Wrapper {
        margin-bottom: 42px;

        @include mobile {
            margin-bottom: 20px;

            .ProductInformation {
                .ExpandableContent {
                    border-top: 0;
                }
            }
        }

        .ExpandableContent-Heading {
            font-size: 18px;
            font-weight: 600;
            text-transform: none;

            @include mobile {
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
}