/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductList {
    @include mobile {
        padding-left: 14px;
        padding-right: 14px;
    }

    &-Header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 59px;
        flex-wrap: wrap;


        a {
            align-items: center;
            display: inline-flex;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;

            @include mobile {
                font-size: 16px;
                display: block;
                margin-left: auto;
                margin-bottom: 6px;
            }

            &:hover {
                path {
                    stroke: $primary-color-1;
                }
            }

            path {
                stroke: $extra-color-1;
            }

            svg {
                margin-left: 3px;

                @include mobile {
                    transform: translate(0px,3px);
                }
            }
        }
    }

    &-Slider {
        padding: 0;

        .ProductCard {
            width: auto;
            max-width: unset;

            &-NotifyButton {
                @include desktop {
                    height: 46px !important;
                    padding: 5px;
                }
            }
        }

        .slick {
            &-list {
                min-height: 460px;
                margin: 0 -7px;
            }

            &-next {
                right: -5px;
            }

            &-next,
            &-prev {
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
            }

            &-prev {
                left: -5px
            }

            &-slide {
                padding-inline: 14px;

                @include mobile {
                    padding: 0 6px;
                }
            }

            &-track {
                padding: 20px 0;

                @include mobile {
                    padding-bottom: 14px;
                }
            }

            &-slider {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &.ProductListWidget {
        padding-bottom: 0;

        h2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 42px;

            @include mobile {
                font-size: 26px;
                margin-bottom: 10px;
            }
        }
    }
}