/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.GroupedProductsItem {
    border-top: 1px solid $neutral-color-6;
    padding: 30px 0;

    @include mobile {
        padding: 20px;
        padding-inline-end: 0;
    }

    &:last-of-type {
        border-bottom: 1px solid $neutral-color-6;
    }

    &:not(:last-of-type) {
        margin-block-end: 0;
    }

    @include mobile {
        grid-template-columns: 84px 1fr;
    }

    .Field-Wrapper {
        display: flex;
        justify-content: flex-end;
        margin-block-start: 20px;

        @include mobile {
            margin-block-start: 0;
        }
    }

    .Image {
        grid-row: 1/3;
    }

    .ProductActions {
        &-CustomPriceInfo {
            color: $font-color-1;
            font-size: 12px;
            margin-top: 10px;

            @include mobile {
                font-size: 10px;
    
                @media screen and (max-width: 367px) {
                    margin-bottom: 0;
                    margin-top: -8px;
                }
            }
        }

        &-Omnibus {
            font-size: 14px;

            @include mobile {
                font-size: 16px
            }
        }
    }

    .ProductPrice {
        &_hasDiscount {
            align-items: flex-end;
            display: flex;
            flex-direction: row-reverse;

            @include mobile {
                align-items: flex-start;
                flex-direction: column-reverse;
            }
        }

        &-HighPrice {
            @include mobile {
                margin-inline-end: 0;
            }
        }
    }

    &-Authors {
        color: $font-color-2;
        font-size: 16px;
        font-weight: 600;
        margin-block-end: 2px;
        max-width: 370px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include mobile {
            display: none;
        }
    }

    &-DiscountAmount,
    &-Omnibus {
        color: $font-color-2;
        display: block;
        font-size: 14px;
        line-height: 20px;
        margin-block-start: 4px;

        @include mobile {
            font-size: 16px
        }

        @include mobile {
            display: none;
        }
    }

    &-RightSection {
        min-width: 260px;
        text-align: end;

        .Field {
            &_type {
                &_numberWithControls {
                    height: 48px;

                    input {
                        width: 58px;
                    }

                    svg {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }

        button {
            height: 48px !important;
            width: 48px !important;
        }

        @include mobile {
            align-items: center;
            display: flex;
            grid-column: 2/3;
            justify-content: space-between;
            margin-block-start: 12px;
            min-width: auto;
            flex-wrap: wrap;
        }
    }

    &-Stock {
        align-items: center;
        background: transparent;
        border-radius: 24px;
        color: $font-color-2;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        inset-inline-start: -7px;
        min-height: 24px;
        padding: 0 8px 0 24px;
        max-width: fit-content;

        @include mobile {
            margin-block-end: 5px;
        }

        &::before {
            background: $red-color-2;
            border-radius: 10px;
            content: '';
            height: 10px;
            inset-inline-start: 8px;
            position: absolute;
            width: 10px;
        }

        &_status {
            background: $green-color-1;

            &::before {
                background: $green-color-2;
            }

            &_announcements,
            &_presale,
            &_to_order {
                background: $orange-color-1;

                &::before {
                    background: $orange-color-2;
                }
            }
        }

        &_onlyBookstore {
            background: $orange-color-1;

            &::before {
                background: $orange-color-2;
            }
        }
    }

    &-Title {
        a {
            font-size: 20px;
            font-weight: 600;
            margin-block-end: 2px;
            max-width: 370px;
            min-width: 370px;
            margin-bottom: 6px;
            margin-top: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

            @include mobile {
                font-size: 16px;
                font-weight: 400;
                min-width: unset;

                @media (max-width: 510px) {
                    max-width: 340px;
                }

                @media (max-width: 480px) {
                    max-width: 290px;
                }

                @media (max-width: 440px) {
                    max-width: 250px;
                }

                @media (max-width: 390px) {
                    max-width: 200px;
                }
            }
        }
    }

    &-Year,
    &-EAN {
        margin-block-end: 2px;
        color: $font-color-2;
        font-size: 16px;
        font-weight: 400;

        @include mobile {
            display: none;
        }

        span {
            color: $font-color-2;
            font-weight: 600;
            margin-inline-start: 6px;
        }
    }
}
