/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --carousel-scroll-item-height: 86px;
}

.ProductGallery {
    margin: 0;

    @include desktop {
        max-width: 642px;
    }

    @include mobile {
        margin-top: 16px;
        max-height: 450px;
    }

    .ProductLabels {
        &-Labels {
            top: 10px;
            padding: 0 5px;
        }

        &-Label {
            padding: 13px 5px;
            margin: 0 5px;
            min-width: 46px;
            min-height: 46px;
        }
    }

    &-SliderImage {
        img {
            height: 100% !important;
            width: 100%;
            transform: translateY(0) !important;
        }
    }

    .Slider-Arrow {
        svg {
            path {
                stroke: $black;
                stroke-width: 3;
            }
        }
    }

    .Slider-Arrow_isNext {
        right: -25px;
    }

    .Slider-Arrow_isPrev {
        left: -25px;
    }

    .Slider {
        &-Crumbs {
            @include mobile {
                bottom: 0;
                grid-gap: 12px;
            }
        }

        &-Crumb {
            @include mobile {
                width: 12px;
                height: 12px;
                background-color: $neutral-color-17;

                &_isActive {
                    background-color: $extra-color-2
                }
            }
        }
    }

    &-Additional {
        .CarouselScrollItem {
            &_isActive {
                border: 1px solid $extra-color-1;
            }
        }

        &_isGrouped {
            .CarouselScroll {
                &-ContentWrapper {
                    max-width: 354px;
                }
            }
        }
    }
}