/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

[dir="ltr"] .ProductInformation {
    &-Description {
        @include desktop {
            padding: 10px 0;
            max-width: 1130px;
            margin: 0 auto;
        }

        h2,
        h3 {

            &,
            >strong,
            >span {
                font-weight: 600 !important;
                font-size: 20px;
                line-height: 26px;
                color: #383838;

                @include mobile {
                    font-size: 18px;
                }
            }

            margin-top: 40px;

            @include mobile {
                margin-top: 30px;
            }

            +p {
                margin-top: 6px;
            }

            &:first-of-type {
                @include mobile {
                    margin-top: 0;
                }
            }
        }

        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #626262;

        @include mobile {
            line-height: 20px;
            font-size: 16px;
        }

        @include description-tags {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #626262;

            @include mobile {
                line-height: 20px;
                font-size: 16px;
            }

            br+br {
                display: none;
            }
        }

        ol,
        ul {
            list-style-type: disc !important;
            padding-left: 20px !important;
            margin-top: 6px;
            margin-left: 0;

            >p {
                margin-left: -20px;
            }

            ul {
                padding-left: 0 !important;
            }

            li {
                word-break: break-all;

                &::before {
                    left: -27px;
                    padding-right: 8px;
                }

                >p {
                    display: inline;
                    margin: 0;
                    word-break: break-all;
                }

                a,
                a>* {
                    color: $extra-color-12;
                    font-weight: 600 !important;
                    font-size: inherit;
                    line-height: 20px;
                    text-decoration: none;
                    word-break: break-all;
                }

                a {
                    &:hover {
                        color: $primary-color-1;
                    }
                }
            }
        }

        strong {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #626262;
        }

        a {
            font-size: 16px;
            font-weight: 600;

            @include mobile {
                font-size: 16px;
            }
        }
    }

    &-ExpandableContentContent {
        margin-top: 0;
    }

    &-Wrapper {
        padding: 0;
    }
}