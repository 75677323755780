/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductLinks {
    &-Slider {

        .ProductCard {
            &-NotifyButton {
                @include desktop {
                    height: 46px !important;
                    padding: 5px;
                }
            }
        }

        .slick {
            &-list {
                min-height: 460px;
                margin: 0 -7px;
            }

            &-next {
                right: -6px;
            }

            &-next,
            &-prev {
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
            }

            &-prev {
                left: -6px
            }

            &-slide {
                padding-left: 14px;
                padding-right: 14px;
            }

            &-track {
                padding: 20px 0;
            }
        }

        .ProductCard-LinkInnerWrapper:hover {
            box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.15);
        }
    }
}