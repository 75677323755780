/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.AskAboutProductPopup {
    .Popup-Content {
        padding: 20px;

        @include mobile {
            padding-top: 50px;
        }
    }

    .Popup-Heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: $font-color-1;
        margin: 0 0 6px;
    }

    &_Desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $font-color-2;
        margin-bottom: 34px;
        @include mobile {
            font-size: 16px
        }
    }

    .Field-Label {
        font-weight: 600;
    }

    input, textarea {
        width: 100%;
    }

    textarea {
        height: 160px;
    }

    &-Wrapper {
        .Button {
            margin-top: 20px;
            width: 100%;
        }
    }
}
